import React from "react";
import { useParams } from "react-router-dom";
import { useT } from "../../../hooks/useT";
import { useState } from "react";
import { OrganizationForm } from "../forms/OrganizationForm";
import { product, programme, project } from "../../../queries";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { translateStatusCode } from "../../../util/translateStatusCode";
import { useToast } from "../../../hooks/useToast";
import { ProfileIcon } from "../../../base/ProfileIcon";
import { Box, Button, Stack, Typography, Tooltip } from "@mui/material";
import ReactQuill from "react-quill-new";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { EditOutlined } from "@mui/icons-material";
import { DeleteButton } from "@/base/DeleteButton";
import { useFlag } from "@/hooks/useFlag";

const hash = (
  email,
  role, // Functional version of Java hash from https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
) =>
  email !== undefined && role !== undefined
    ? email
        .split("")
        .reduce(
          (hash, char) => ((hash << 5) - hash + char.charCodeAt()) >>> 0,
          0,
        )
        .toString(16) +
      "-" +
      role
    : undefined;

export const OrganizationDropDown = ({
  toggle,
  name,
  role,
  email,
  onSubmit,
  category,
  validRoles,
  details,
  refetch,
  type,
  tKey = "project.organization",
}) => {
  const { id = "" } = useParams();
  const { t } = useT();
  const toast = useToast();

  const useEditRole = useFlag("project.editRole");

  const [editForm, toggleEditForm] = useState(false);

  const deleteRole = useMutation(
    type === "project"
      ? project.deleteRole
      : type === "programme"
        ? programme.deleteRole
        : product.deleteRole,
    {
      onSuccess: () => {
        toast.success(t("project.organization.form.success.delete"));
        refetch();
      },
      onError: (data) => {
        toast.error(t(translateStatusCode(data.response.status)));
      },
    },
  );
  const deleteMemberForm = useFormik({
    initialValues: { email: email, role: role },
    onSubmit: (v) => {
      deleteRole.mutate({
        id: id,
        roleId: hash(email, role),
        data: { email: email, role: role },
      });
    },
  });
  return (
    <Box
      style={{
        width: "100%",
        columnSpan: "all",
        borderTop: "1px solid #E5E7EB",
      }}
    >
      {editForm ? (
        <Stack>
          <Box>
            <Typography
              variant="h2"
              me
              sx={{
                marginLeft: "25px",
                marginTop: "30px",
                float: "left",
                borderBottom: "1px solid #E5E7EB",
                width: "30%",
              }}
            >
              {role != undefined
                ? !role.includes("Custom-")
                  ? t(tKey + "." + category + ".roles." + role + ".title")
                  : role.replace("Custom-", "")
                : ""}
              {!t(tKey + "." + category + ".roles." + role + ".help").includes(
                tKey,
              ) ? (
                <Tooltip
                  sx={{ marginLeft: 1 }}
                  title={
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: t(
                          tKey + "." + category + ".roles." + role + ".help",
                        ),
                      }}
                    />
                  }
                >
                  <InfoOutlinedIcon />
                </Tooltip>
              ) : null}
            </Typography>
          </Box>
          <OrganizationForm
            isOpen={editForm}
            initialCustomRole={
              role.includes("Custom-") ? role.replace("Custom-", "") : ""
            }
            formMethod={
              type === "project"
                ? useEditRole
                  ? project.editStakeholder
                  : project.editRole
                : type === "programme"
                  ? programme.editRole
                  : product.editRole
            }
            onSubmit={onSubmit}
            toggle={toggleEditForm}
            details={details}
            category={category}
            roleId={hash(email, role)}
            name={name}
            role={role}
            email={email}
            validRoles={validRoles}
            refetch={refetch}
            isEdit={true}
            tKey={tKey}
            type={type}
          />
        </Stack>
      ) : (
        <Stack sx={{ p: 3 }} spacing={2}>
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <DeleteButton
              onConfirm={deleteMemberForm.handleSubmit}
              content="Are you sure you want to delete this role?"
            />
            <Button
              variant="text"
              color="secondary"
              startIcon={<EditOutlined />}
              onClick={() => toggleEditForm(!editForm)}
            >
              {t("generic.button.edit")}
            </Button>
          </Stack>
          <Typography
            variant="h2"
            sx={{
              borderBottom: "1px solid #E5E7EB",
              width: "30%",
            }}
          >
            {role != undefined
              ? !role.includes("Custom-")
                ? t(tKey + "." + category + ".roles." + role + ".title")
                : role.replace("Custom-", "")
              : ""}
            {!t(tKey + "." + category + ".roles." + role + ".help").includes(
              tKey,
            ) ? (
              <Tooltip
                sx={{ marginLeft: 1 }}
                title={
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: t(
                        tKey + "." + category + ".roles." + role + ".help",
                      ),
                    }}
                  />
                }
              >
                <InfoOutlinedIcon />
              </Tooltip>
            ) : null}
          </Typography>
          <Stack direction={"row"} spacing={2} sx={{ mt: 2.5 }}>
            <ProfileIcon name={name} sx={{ height: "40px", width: "40px" }} />
            <Stack>
              <Typography variant="h3">{name}</Typography>
              <Typography variant="h4">{email}</Typography>
            </Stack>
          </Stack>
          <ReactQuill
            style={{
              background: "#F3F4F6",
              width: "100%",
              height: "184px",
            }}
            readOnly
            value={details}
            modules={{ toolbar: false }}
          />
        </Stack>
      )}
    </Box>
  );
};
