import React, { memo } from "react";
import { useQ } from "@/hooks/useQ";
import { useT } from "@/hooks/useT";
import { project } from "@/queries";
import { clientUrl } from "@/util/routing";
import { uris } from "@/config/nav";

import { LinkItem } from "./DrawerNavigation";
import { ToggleMenu } from "./ToggleMenu";
import { useFlag } from "@/hooks/useFlag";

function lessonCount(lessons: { lessons: { type: string }[] }, type: string) {
  const count = lessons?.lessons?.filter((lesson) => lesson.type === type)
    .length;
  return count || 0;
}

export const LessonsNavigation = memo(() => {
  const { t } = useT();
  const { data: lessons = [] } = useQ(`project-lessons`, () =>
    project.lessons(),
  );
  const useDisableProgrammeAndProduct = useFlag("disableProductAndProgramme");

  const items: LinkItem[] = [
    {
      id: "projects",
      label:
        t("project.plans.lessons.filter.project") +
        ` (${lessonCount(lessons, "project")})`,
      to: clientUrl(uris.lessons, { scope: "project" }),
    },
    {
      id: "portfolios",
      label:
        t("project.plans.lessons.filter.portfolio") +
        ` (${lessonCount(lessons, "portfolio")})`,
      to: clientUrl(uris.lessons, { scope: "portfolio" }),
    },
    ...(useDisableProgrammeAndProduct
      ? []
      : [
          {
            id: "programmes",
            label:
              t("project.plans.lessons.filter.program") +
              ` (${lessonCount(lessons, "programme")})`,
            to: clientUrl(uris.lessons, { scope: "program" }),
          },
        ]),
    {
      id: "releases",
      label:
        t("project.plans.lessons.filter.corporate") +
        ` (${lessonCount(lessons, "corporate")})`,
      to: clientUrl(uris.lessons, { scope: "corporate" }),
    },
  ];

  return (
    lessons?.lessons?.length > 0 && (
      <ToggleMenu text={t("project.navigation.plans.lessons")} items={items} />
    )
  );
});
